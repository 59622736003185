.alignBox {
  width: 412px;
  margin: auto;
  border-bottom: 2px solid #8175fc;
}
.outBox {
  background: #fff;
  display: flex;
  margin-top: 5rem;
  justify-content: center;
  align-items: center;
  padding: 50px 10px;
  border: 1px solid #e1e2f0;
  border-radius: 3px;
  box-shadow: 0 0 5px 0 rgb(42 45 48 / 12%);
}
.ublSp {
  padding-top: 6% !important;
  padding: 0 30px;
  position: relative;
}
.ublSp h1 {
  color: #666666;
  font-weight: 300;
  font-size: 19px;
  padding-bottom: 5%;
}
.ublSp h1,
.signupLabel {
  text-align: center;
}
.inputContainer a,
.signupLabel a {
  color: #01b49e;
  font-weight: bold;
}
.ublSp img {
  position: absolute;
  top: -5%;
  width: 33px;
  right: 45%;
}
.ParaGraph {
  font-size: 14px;
  color: #adadad;
  text-align: center;
}
.forgetPassword {
  font-size: 12px;
  top: 68%;
  font-weight: 600;
  color: #01b49e;
  cursor: pointer;
  margin: 0;
  float: right;
}
.ubdr {
  border-left: 1px solid gray;
}
.inpVl {
  padding: 10px 0 8px 30px !important;
  width: 300px !important;
  border-radius: 5px !important;
  border: 1px solid gray !important;
  color: #666666 !important;
  font-size: 16px !important;
}
.inpVl:focus {
  border: 1px solid #01b49e !important;
  outline: none !important;
}

.inpVl:focus + .faIc {
  filter: grayscale(0%);
}

.inputContainer {
  margin: 15px 0;
  position: relative;
}

.otpInputContainer {
  margin: 15px 0 0 0;
  position: relative;
}

.inpIcon {
  filter: grayscale(100%);
  position: absolute;
  top: 0;
  padding: 9px;
}

.inpIcon img {
  width: 15px;
  height: auto;
}
.gtPwd {
  display: inline-block;
  text-align: right;
  width: 100%;
  margin: 10px 0;
}

.signupBtn {
  font-weight: 600;
  background-color: #01b49e;
  border: 2px solid #01b49e;
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  color: #ffffff;
  cursor: pointer;
  margin-top: 6%;
}
/* new ui on login */
.outer {
  position: absolute;
  left: 0;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: table;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  padding-bottom: 100px;
}

.outer iframe {
  pointer-events: unset !important;
}

.loginContainer {
  max-width: 390px;
}
.inner {
  margin-left: auto;
  margin-right: auto;
  width: 400px;
  /* Whatever width you want */
}
.bottom-card {
  height: 10px;
  border-radius: 0 0 8px 8px;
  margin: 0 10px;
  background: #f3f4f6;
}
.align_items_center {
  align-items: center !important;
}
.justify_content_center {
  justify-content: center !important;
}
.h_100 {
  height: 100% !important;
}
.card {
  --bs-card-spacer-y: 1.25rem;
  --bs-card-spacer-x: 1.25rem;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-border-width: 0;
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: 0.3rem;
  --bs-card-box-shadow: 0 0.5rem 1remrgba (0, 0, 0, 0.05);
  --bs-card-inner-border-radius: 0.3rem;
  --bs-card-cap-padding-y: 0.625rem;
  --bs-card-cap-padding-x: 1.25rem;
  --bs-card-cap-bg: rgba(0, 0, 0, 0.03);
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: #fff;
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 0.925rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 5%);
  border-radius: 8px;
}
.card_body {
  padding: 1.5rem !important;
  margin: 0.25rem !important;
  flex: 1 1 auto;
  color: var(--bs-card-color);
}
.heading {
  font-weight: 400;
  line-height: 1.2;
  padding-bottom: 0.5rem !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  font-size: 1.5rem;
}
.loginBox {
  display: table-cell;
  vertical-align: middle;
}

.loginResend {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 16px 0 2px 0;
  border: 1px solid #f3f3f3;
  border-radius: 6px;
  padding: 5px;
  background-color: #f3f3f3;
}

.loginResendText {
  color: #aba9a9;
}

.loginResendTimer {
  color: #07bc0c;
  border: 2px solid #07bc0c;
  border-radius: 50%;
  padding: 7px 9px;
  font-size: 16px;
  margin: 0;
  width: 6%;
  margin-left: 5px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
.login_input input {
  border-left: 1px solid #aba9a9;
  border-radius: 0 !important;
  padding-left: 10px;
}
.bottom_card {
  height: 10px;
  border-radius: 0 0 8px 8px;
  margin: 0 10px;
  background: #f3f4f6;
}
.login_footer {
  font-size: 17px;
  text-align: center;
  position: absolute;
  bottom: 120px;
  left: 0;
  right: 0;
}
.login_footer a {
  color: #666666;
  text-decoration: none;
  margin: 10px;
}
@media (min-width: 576px) {
  .form {
    /* width: 300px; */
  }
}
@media (max-width: 480px) {
  .inpVl {
    width: 80%;
  }
  .alignBox {
    width: 95%;
  }
  .gtPwd {
    margin: 0;
  }
  .ublSp {
    padding: 0 15px;
  }
  .signupBtn,
  .inputContainer a,
  .signupLabel a {
    font-size: 11px;
  }
}
