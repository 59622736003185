.kyc_detail_ctrl .MuiSwitch-thumb {
  color: #1976d2 !important;
}
.kyc_detail_ctrl .MuiTouchRipple-root{
  color: #1976d2 !important;
}
.premium-file input{
  padding: 8px 10px 16px 10px;
  height: 15px !important;
}
.timeSlot-field {
  width: 100% !important;
}
.timeSlot-field .merchant_list_search_bar {
  width: 100% !important;
}
.timeSlot-field .MuiCheckbox-root, .timeSlot-cell-signzy .MuiCheckbox-root{
  padding-left: 0;
}
.timeSlot-cell-signzy {
  width: 33%;
  float: left;
}
.clearfix {
  overflow: auto;
}
.add_menu_role_star span {
  color:#666666 !important;
}
.MuiTooltip-tooltip {
  background-color: #F8FAFF !important;
  color: black !important;
}
.info-img {
  width: 3%;
  margin-left: 7px;
  margin-bottom: -3px;
}
.MuiTooltip-tooltip h4 {
  margin-bottom: 0;
}
.MuiTooltip-tooltip p {
  margin-top: 0;
}
.img-quality-tooltip ul {
  list-style: none;
}

.img-quality-tooltip ul li::before {
  content: "\2022";
  color: black;
  font-weight: bold; 
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}
.timeSlot_edit_drawer_btn_sec {
  margin-top: 1rem;
}
.add_tax_btn {
  margin-left: 0 !important;
  background-color: #00c9a5 !important;
}
.timeSlot_upload_btn {
  min-width: 0!important;
  margin: 10px 0!important;
  background-color: #1976d2!important;
  color: #fff!important;
}