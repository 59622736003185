.all-order-table td{
    padding-top: 20px !important;
}
.completed-status {
    color: green !important;
}
.cancelled-status {
    color: red;
}
.customer-order-chart {
    .MuiPaper-root {
        width: 95%;
        max-width: 95%;
    }
}
.crm-btn {
    margin: 15px !important;
    padding: 15px !important;
    background-color: #01b49e  !important;
}
.bulk-input {
    width: 520px !important;
    /* height: 2px !important; */
    background-color: #FEFEFE;
}