.customized-dialog-title {
  color: #666666 !important;
  font-family: "whitney", sans-serif !important;
  font-weight: 500 !important;
  font-size: 20px !important;
}
.customized-dialog-title p {
  color: #898989 !important;
  font-family: "whitney", sans-serif !important;
  font-size: 500;
}
.dilog_body p {
  color: #898989 !important;
  margin-top: -20px !important;
  font-family: "whitney", sans-serif !important;
}

.dilog_body .upload_btn {
  background-color: #1058ff !important;
  font-family: "whitney", sans-serif !important;
}
.dilog_body {
  border: 1.5px dashed #898989 !important;
  border-radius: 20px !important;
  margin-left: 70px !important;
  margin-right: 70px !important;
  padding: 15px !important;
  font-family: "whitney", sans-serif !important;
}

.dilog_body img {
  height: 100px !important;
  width: 100px !important;
  text-align: top !important;
  margin: 0px !important;
}
.success_dilog_body img {
  height: 350px !important;
  width: 350px !important;
  text-align: top !important;
  margin: 0px !important;
}
.success_dilog_box .success_ok_btn {
  background-color: #1058ff !important;
  color: #fff !important;
  text-align: center !important;
}

.success_upload .css-153eyq5-MuiModal-root-MuiDialog-root{
    justify-content: center !important;
}
 
/* imageupload */
.file-display-container {
    position: fixed;
    /* width: 805px; */
}

.file-status-bar{
    width: 100%;
    vertical-align:top;
    margin-top: 10px;
    margin-bottom: 20px;
    position: relative;
    line-height: 50px;
    height: 50px;
}

.file-status-bar > div {
    overflow: hidden;
}

.file-type {
    display: inline-block!important;
    position: absolute;
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
    margin-top: 15px;
    padding: 0 4px;
    border-radius: 2px;
    box-shadow: 1px 1px 2px #abc;
    color: #fff;
    background: #0080c8;
    text-transform: uppercase;
}

.file-name {
    display: inline-block;
    vertical-align:top;
    margin-left: 50px;
    color: #4aa1f3;
}

.file-error {
    display: inline-block;
    vertical-align: top;
    margin-left: 50px;
    color: #9aa9bb;
}

.file-error-message {
  color: red;
  /* position: absolute; */
  font-size: 12px;
  position: absolute;
  width: 219%;
  bottom: -41%;
  /* left: 0; */
  left: 0;
}

.file-type-logo {
    width: 50px;
    height: 50px; 
    background-size: 100%;
    position: absolute;
}

.file-size {
    display:inline-block;
    vertical-align:top;
    color:#30693D;
    margin-left:10px;
    margin-right:5px;
    margin-left: 10px;
    color: #444242;
    font-weight: 700;
    font-size: 14px;
}

.file-remove  {
    position: absolute;
    top: 18px;
    right: 10px;
    line-height: 15px;
    cursor: pointer;
    color: red;
    margin-right: -50px;
}
.closefile {
  margin-top:10px;
}