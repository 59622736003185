.colleps_arrow {
  border: 1px solid #323232;
  color: #01b49e;
  border-radius: 50%;
  font-size: 15px !important;
  padding: 4px !important;
}
/* .navbar_arrow_div img{
  height: 30px !important;
  width: 30px !important;
} */
.position_change {
  position: relative !important;
  float: right !important;
  border-radius: 50% !important;
  /* padding: 15px !important; */
}
.btn_icon_rad {
  border-radius: 50% !important;
}

.collapes_nav_paper {
  border-radius: 0px 7px 7px 0px !important;
  height: 100vh !important;
}

.navbar_logo_dev {
  padding: 0px 15px 0px 15px !important;
  border-bottom: 0.5px solid #c9c9c9 !important;
  margin-left: -5px !important;
}
.configuration_collaps span {
  font-size: 13px !important;
}
.configuration_collaps .css-ishquk-MuiButtonBase-root-MuiListItemButton-root {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
}
.navlist {
  overflow-y: auto !important;
  height: 80vh !important;
}
.navlist::-webkit-scrollbar {
  width: 0 !important;
}
.navbar_arrow_div {
  padding: 2px 2px 0px 0px !important;
  margin-left: 18px !important;
}
.navbar_lits_item_text {
  color: #666666 !important;
  font-weight: 200 !important;
}
.navbar_collapes_box {
  width: 100% !important;
  border-radius: 0px 10px 10px 0px !important;
  height: 100vh !important;
}
.collapes_close_navbar {
  border-radius: 0px 7px 7px 0px !important;
  height: 100vh !important;
}
.logout_icon {
  color: #666666 !important;
  margin-left: 5px !important;
}
.logout_text {
  color: #666666 !important;
}
