.kyc_kyc_main_div{
  padding: 15px !important;
  border-top: 0.5px solid #C9C9C9;
}
.banner_top_div{
  padding: 0px 0px 50px 0px !important;
}
.kyc_kyc_Configuration p {
  color: #666666 !important;
  font-weight: 500 !important;
  font-size: 18px !important;
}

.banner_div {
  display: flex !important;
  align-items: center !important;
}
.banner_div .kyc_kyc_input_grid {
  margin-right: 5px !important;
}