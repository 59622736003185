.merchant_details_inner_title {
  border-bottom: 0.5px solid #c9c9c9 !important;
}
.expanded_bar {
  border: none !important;
  border-bottom: 0.5px solid #c9c9c9 !important;
  background-color: white !important;
}
.statusbar-acquirer{
    /* background-color: #dfdfdf4d; */
    margin-top: 11px;
    padding: 15px;
}
.editmargin{
  margin-left: auto !important;
  margin-right: 1% !important;

}
.margintop p{
  margin-top:9px;
}

.merchant_detail_tab_sec .merchantTool {
  color: gray;
  padding: 0%;
  font-size: 14px !important;
}

.merchant_detail_tab_sec button {
  font-size: 18px !important;
  font-weight: 500 !important;
  font-family: "whitney", sans-serif !important;
  text-transform: none !important;
}

.merchant_detail_tab_sec
  .css-g29qca-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #1058ff !important;
}
.merchant_detail_tab_sec .css-1aquho2-MuiTabs-indicator {
  background-color: #1058ff !important;
}

.activeSwitch {
  display: flex;
  align-items: center;
}

.activeSwitch p {
  margin-right: 10px;
}

.merchant_detail_tab_input_sec {
  margin-right: 20px !important;
}
.merchant_detail_tab_inpt_grup {
  padding: 20px 0px 0px 12px !important;
}
.merchant_details_tab_input_icon{
  margin-right: -15px !important;
}
.merchant_details_tab_input_icon img {
  height: 18px !important;
  width: 18px !important;
}
.merchant_details_tab_input_icon .icon{
  margin-right: -20px !important;
  height: 18px !important;
  width: 18px !important;
}
.merchant_details_tab_input_location_icon img {
  height: 25px !important;
  width: 25px !important;
}
.merchant_detail_tab_main_page .branch_add_btn{
  margin-bottom: 10px !important;
  font-size: 12px !important;
  text-transform: uppercase !important;
  margin-left:auto !important;
}
.merchant_detail_tab_save-cancel_btn{
  width: 120px !important;
}
.buttonactive{
  width: 107px !important;
    height: 35px !important;
    background-color: antiquewhite !important;
    font-weight: bold!important;
}
.kycedit{
  display: flex !important;
    margin-left: auto;
    margin-right:1% !important;
}
.blockcomments{
  width: 180% !important;
}
.topmargin10{
  margin-top:10px !important;
}
.merchant-kyc-detail-panel p {
  margin-top: 9px;
}
.AcquireListMerchant {
  border-radius: 5px;
    background-color: #ffffff !important;
    margin-top: 15px !important;
    border-radius: 5px !important;
    padding-left: 1%;
    padding-right: 1%;
    padding-top: 3px;
}
.AcquireListMerchant .BaseAcquireList{
  margin-bottom: 2%;
}
.merchant_edit_button_dev {
  margin: 25px 0!important;
}