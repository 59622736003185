.reply-status {
    background: green;
    color: white;
    padding: 2px 5px;
    border-radius: 4px;
}
.pending-status {
    background: red;
    color: white;
    padding: 2px 5px;
    border-radius: 4px;
}
.table-qr-section{
    text-align: center;
    width: 25%;
    padding: 20px;
    color: black;
    background-color: white;
}
.table-qr-section .footer{
    margin: 0 -20px -20px -20px;
    padding: 7px;
    background: #01b49e;
    font-size: 20px;
    font-weight: bold;
    color: #fff;
}
.table-qr-section .qr-img {
    width: 70%;
    padding: 10px;
    border-radius: 12px;
    box-shadow: 0px 0px 3px 0px #ababab;
    margin: 10px;
}
.table-qr-section .terminal-table-img { 
    width: 15%;
    margin-bottom: 10px;
}
.table-qr-section label {
    margin: 0 5px; 
}