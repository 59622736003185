.dashboard-section {
  padding: 14px;
  margin-top: 14px;

  &-main {
    padding: 14px;
    background-color: #ffffff;
    margin-bottom: 26px;
    border-radius: 8px;
  }

  hr {
    border-color: #ffffff42;
    outline: none;
    margin: 10px auto 20px auto;
    width: 99%;
  }
  hr:nth-child(1) {
    margin: 20px auto;
    margin-top: 20px;
  }
}

.download-invoice-btn {
  cursor: pointer;
  color: blue;
  float: right
}
// .no-space-collapse {
//   word-spacing: normal; /* Prevent word spacing collapse */
//   white-space: normal;  /* Maintain normal white space */
//   word-break: break-word; /* Ensure words break naturally */
// }
.offscreen {
  width: 100%;
  table, th, td {
    border: 1px solid black;
    border-collapse: collapse;
    background: white;
  }
  .theme-color {
    color: #01b59c;
    font-weight: 400;
  }
  .details {
    border: 1px solid black;
    padding: 5px;
    background: white !important;
    margin: 15px 0;
  }
  .line-r {
    border-right: 1px solid #01b59c;
  }
  .total {
    border: 1px solid black;
    border-radius: 8px;
    padding: 10px;
    text-align: center;
    margin: 15px 0;
    background-color: lightgray;
    overflow:hidden;
    page-break-inside: avoid;
  }
  .entries {
    margin-bottom: 15px;
  }
  .report-table {
      width: 100%;
      border-spacing: 0pt;
      border-collapse: separate;
  }
  .report-table th {
      background: #ddd;
  }
  .report-table th,
  .report-table td {
      padding: 0.75rem;
      border: 1px solid black;
  }
  .report-table th:first-child {
      border-top-left-radius: 8px;
  }
  .report-table th:last-child {
      border-top-right-radius: 8px;
  }
  .report-table tr:last-child td:first-child {
      border-bottom-left-radius: 8px;
  }
  .report-table tr:last-child td:last-child {
      border-bottom-right-radius: 8px;
  }
  .give .txt {
    color: green;
  }
  .get .txt {
    color: red;
  }
  .give .txt span,
  .get .txt span {
    color: black
  }
  .txt-gray {
    color: lightgray;
  }
  #header {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    height: 120px;
    z-index: -1;
  }
  #footer {
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    height: 60px;
  }
  .header-space {
    height: 100px;
  }
  .footer-space {
    height: 60px;
  }
  .report-name{
    color: white;
    text-align: right;
  }
  .bg-white {
    background: white;
  }
  .row {
    display: flex;
    flex-wrap: wrap;
    margin-right: 0;
    margin-left: 0;
  }
  .col {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
    padding-right: 15px;
    padding-left: 15px;
  }
  .company-detail {
    width: 60%;
  }
  .company-detail h4 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .order-no-section {
    width: 20%;
  }
  .cell-section{
    padding: 10px 0;
  }
  .items-table th, .items-table td {
    text-align: left;
    padding: 7px 10px;
  }
  .total-amount-section {
    text-align: right;
  }
  .invoice-heading {
    margin: 15px;
    text-align: center;
  }
  .bill-to-section {
    width: 50%;
  }
  .signature-section {
    text-align: center;
  }
  tr, td {
    page-break-inside: avoid;
  }
  .invoice-heading {
    page-break-before: always
  }
  .avoid-break-page {
    page-break-before: auto; /* Prevents unnecessary page break for the first element */
  }
}
