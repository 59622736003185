.configuration_tab button {
  font-size: 15px !important;
  font-weight: 500 !important;
  font-family: "whitney", sans-serif !important;
}
.configuration_tab .css-g29qca-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #1058ff !important;
}
.configuration_tab .css-1aquho2-MuiTabs-indicator {
  background-color: #1058ff !important;
}
.configuration_tab_div{
  padding:10px 20px !important;
}
.configuration-heading {
  color: #666666 !important;
  font-weight: 500 !important;
  font-family: "whitney", sans-serif !important;
  font-size: 1rem !important;
}
.master-config {
  border-top: 0.5px solid #c9c9c9!important;
  border-bottom: 0.5px solid #c9c9c9!important;
  margin-bottom: 20px!important;
}
.master-config p {
  color: #666!important;
  font-weight: 500!important;
  font-size: 1rem!important;
  font-family: "whitney",sans-serif!important;
}
.banner_drower, .laundryServices_drower, .timeSlot_drower, .offer_drower {
  background-color: #0bb4a0!important;
  font-size: 25px!important;
  font-weight: 600!important;
  color: #fff!important;
  height: 70px!important;
  font-family: "Whitney",sans-serif!important;
}
.kyc_kyc_main_div{
  padding: 15px !important;
  border-top: 0.5px solid #C9C9C9;
}
.kyc_kyc_top_div{
  padding: 0px 0px 50px 0px !important;
}
.kyc_kyc_Configuration p {
    color: #666666 !important;
    font-weight: 500 !important;
    font-size: 18px !important;
  }
 
  .kyc_kyc_input_div {
    display: flex !important;
    align-items: center !important;
  }
  .kyc_kyc_input_div .kyc_kyc_input_grid {
    margin-right: 5px !important;
  }

  .kyc_kyc_search_bar{
    border-radius: 30px !important;
    background-color: #FEFEFE !important;
  }
  
  .kyc_kyc_search_bar_dt{
    border-radius: 30px !important;
    background-color: #FEFEFE !important;
  }
  /* .kyc_kyc_toggal_btn  {
    margin-left: 20px !important;
  } */

  .kyc_kyc_label_div {
    border-top: 0.5px solid #c9c9c9 !important;
    /* border-bottom: 0.5px solid #c9c9c9 !important; */
    display: flex !important;
    justify-content: flex-end !important;
    align-items: center !important;
    padding: 2px 0px 2px 0px !important;
  }
  .kyc_kyc_grid_feild_div {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    padding-right: 12px !important;
  }
  
  .kyc_kyc_grid_feild_div {
    margin-right: 60px !important;
  
  }
  .kyc_kyc_save_cancel_btn{
      margin-top: 90px !important;
  }
  
  .kyc_kyc_edit_button_dev {
    margin: 25px 0px 25px 0px !important;
  }

  .kyc_kyc_cancel_btn {
    background-color: #ff5b55 !important;
  }

  .kyc_kyc_tab_save-cancel_btn{
    width: 120px !important;
  }

  .kyc_kyc_save_btn {
    background-color: #00c9a5 !important;
    margin-left: 10px !important;
  }

  
.kyc_kyc_drawer_save_cancel_btn{
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}
.kyc_kyc_second_grid_sec  p{
  color: #666666 !important;
}
.kyc_kyc_input_grid p{
  color: #666666 !important;
}
.kyc_kyc_grid_feild_div .kyc_kyc_second_grid_sec {
  margin-right: 26px !important;
}
.kyc_kyc_grid_feild_div .kyc_kyc_second_grid_sec_dt {
  color: #666666 !important;
  margin-right: 32px !important;
  /* margin-left: 5px !important; */
}
/* .status_fix{
  margin-left: 15px !important;
} */
.fm_fix{
  margin-left: -2px !important;
}
  .hidden { display:none; }
  .visible { display:block; }

  .kyc_kyc_input_grid .kyc_duration{
    width: 75px !important;
    color: #9F9F9F !important;
  }
  .kyc_kyc_input_grid .txt_input{
    /* width: 75px !important; */
  }
  .kyc_kyc_input_grid .MuiInputBase-formControl{
    height:42px !important;
  }
  .kyc_kyc_input_grid .MuiSelect-select{
    height:42px !important;
    color: black !important;
    font-size: 14px !important;
    padding: 8.5px 32px 8.5px 14px !important;
  }

  .kyc_kyc_input_grid .MuiSelect-select.Mui-disabled {
    color: rgba(0, 0, 0, 0.38) !important;
  }

  .kyc_kyc_input_grid .css-1x5jdmq .Mui-disabled{
    height:42px !important;
      }

  .kyc_p_save_btn{
    background-color: #00c9a5 !important;
    width: 100px !important;
  }
  .permission-label {
    background-color: #1058ff !important;
    font-size: 25px !important;
    font-weight: 600 !important;
    color: #ffffff !important;
    font-family: "Whitney", sans-serif !important;
    margin: -1px 0 5px 0;
    padding: 20px;
  }
  .kyc_edit_btn{
    min-width: 0 !important;
    background: #00c9a5 !important;
  }
  .kyc_delete_btn{
    min-width: 0 !important;
    background-color: white !important;
    color: #ff5b55 !important;
    margin-left: 5px !important;
  }
  .kyc_cancel_btn{
    min-width: 0 !important;
    background: #ff5b55 !important;
  }
  .kyc_save_btn {
    min-width: 0 !important;
    background: #00c9a5 !important;
    margin-left: 5px !important;
  }
  .kyc_kyc_edit_button_dev {
    display: inline-flex;
  }
  .kyc_detailed_btn {
    min-width: 0 !important;
    margin-top: 2px !important;
    margin-right: 5px !important;
    background-color: white !important;
    color: #1976d2 !important;
  }
  .action-cell{
    min-width: 100px;
  }
  .doc-name-cell {
    min-width: 300px
  }
  /* .MuiBackdrop-root{
    background-color: rgba(0, 0, 0, 0.01) !important;
  } */
  .MuiPaper-elevation16{
    box-shadow: 5px 6px 13px 1px rgb(0 0 0 / 28%) !important;
  }
  .kyc_edit_drawer_tbl_list_box {
    margin-left: 0.5rem !important;
    padding: 10px !important;
    border: 0.5px solid #c0c0c0 !important;
    height: 1rem !important;
    width: 30% !important;
    border-radius: 5px !important;
  }
  .kyc_limit_input {
    margin-left: 5px !important;
    width: 18%;
  }
  .kyc_label_input {
    margin-left: 5px !important;
    width: 18%;
  }
  .kyc_limit_input input, .kyc_label_input input {
    height: 4px !important;
  }
  .kyc_module {
    width: 100%;
    display: inline-flex !important;
  }
  .kyc_module .name_heading {
    width: 40%;
    display: block;
    text-align: center;
    margin-bottom: 10px;
  }
  .kyc_module .limit_heading {
    text-align: center;
    display: block;
    width: 18%;
    margin-bottom: 10px;
  }
  .kyc_module .label_heading {
    width: 18%;
    display: block;
    text-align: center;
    margin-bottom: 10px;
  }
  .kyc_detail {
    width: 40px;
  }
  .kyc_row{
    margin-bottom: 5px;
  }
  .kyc_row_name .txt_input, .kyc_heading_name p{
    width: 100% !important;
  }
  .kyc_array {
    margin: 0 10px 20px 10px;
  }
  .is_default_cell, .active_status_cell, .is_default_head, .active_status_head{
    width: 50%;
  }
  .kyc-list .MuiGrid-root{
    margin-left:0px;
    width: 40% !important;
  }
  .kyc-list .MuiSelect-select {
    color: #666666 !important;
  }
  .document_heading {
    font-weight: bold;
    border-top: 1px solid #666666;
    border-bottom: 1px solid #666666;
    line-height: 1;
  }
  .auto-kyc-label {
    width: 125px !important;
  }
  .doc-name {
    padding-left: 15px;
  }