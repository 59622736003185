.dashboard-status {
  padding: 18px 0 7px 0;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.dashboard-status-card {
  padding: 6px 12px;
  height: 106px;
  background: #ffffff;
  box-shadow: 0px 8px 30px #0000001f;
  border-radius: 5px;
  box-sizing: border-box;
  width: 216px;
  margin: 0 10px 18px 10px;
  cursor: pointer;

  &-top {
    display: flex;
    justify-content: space-between;
    padding: 6px 0;
    h2 {
      margin: 0;
      font-size: 1.13rem;
      font-weight: 600;
    }
    img {
      width: 30px;
    }
  }
  &-bottom {
    display: flex;
    justify-content: space-between;
    padding: 8px 0 6px 0;
    align-items: flex-end;
    height: 38px;
    p {
      margin: 0;
      font-weight: 600;
      font-size: 1.1rem;
      font-weight: normal;
    }
    &__currency {
      color: #7c7c7c;
      font-size: 0.7rem;
    }
  }
}
.custom-dialog .MuiPaper-root {
  max-width: 80% !important;
}