/* This is our universal stylesheet */
/* @import url("https://fonts.cdnfonts.com/css/sf-pro-display"); */
/* @import url("https://fonts.cdnfonts.com/css/whitney"); */
body {
  margin: 0;
  font-family: "Whitney", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #eeeef6;
  overflow: hidden !important;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  font-family: "Whitney", sans-serif !important;
}
a {
  text-decoration: none;
}

input {
  font-family: "Whitney", sans-serif !important;
  font-size: 14px !important;
}

code {
  font-family: "Whitney", sans-serif !important;
}

.containerwidthextra {
  margin-left: 10px !important;
  margin-right: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.css-1kp8ztg {
  height: 0 !important;
}
.css-vycneo {
  height: 0 !important;
}
.css-1oqqzyl-MuiContainer-root {
  max-width: 1600px !important;
}
.css-1oqqzyl-MuiContainer-root {
  padding-left: 10px !important;
  padding-right: 10px !important;
}
.main_children {
  height: 100vh !important;
  width: 100%;
  overflow-y: scroll !important;
}
html {
  scrollbar-width: normal;
  scrollbar-color: #2160f6 !important;
  border-radius: 50rem;
}
::-webkit-scrollbar:horizontal {
  height: 0.4rem !important;
}
::-webkit-scrollbar {
  width: 0.4rem;
  border-radius: 50rem;
}
iframe {
  z-index: -1 !important;
}
::-webkit-scrollbar-thumb {
  background-color: #01b49e !important;
  border-radius: 50rem;
}
::-webkit-scrollbar-track {
  border: 1px solid;
  border-radius: 50rem;
  border-color: #c0c0c0 !important;
}

.popup-content {
  width: auto !important;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
.kyc-doc-date .MuiOutlinedInput-notchedOutline{
  border-color: rgba(0, 0, 0, 0.23) !important;
}

.otp-input {
  width: 100% !important;
  padding: 15px 2px;
  border-radius: 5px;
  box-shadow: none;
  margin: 6px;
  border: 1px solid #ababab;
}
.thm-heading-p-margin {
  margin: 10px 0;
}
.loader_data_tbl {
  text-align: center;
  padding: 20px;
}
.delete_btn {
  min-width: 0 !important;
  margin-top: 2px !important;
  margin-right: 5px !important;
  background-color: white !important;
  color: red !important;
}
.order_drawer_main .css-1160xiw-MuiPaper-root-MuiDrawer-paper {
  background-color: #f3f4f6 !important;
  border-radius: 10px 0px 0px 10px !important;
}
.order_drawer_main .css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop {
  background-color: #ffffff00 !important;
}
.order_details_drawer_top_sec_list {
  border-bottom: 0.5px solid #c9c9c9 !important;
}
.order_details_drawer_top_sec {
  background-color: #ffffff !important;
  height: 70px !important;
  display: flex !important;
  justify-content: space-between !important;
  padding: 40px 30px !important;
}
.order_details_drawer_top_sec p {
  color: #666666 !important;
  font-weight: 500 !important;
  font-size: 20px !important;
}
.order_details_drawer_top_sec span {
  color: #1058ff !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  margin-top: 10px !important;
}
.order_details_drawer_refund_div {
  height: 35px !important;
  max-width: 170px !important;
  border: 0.5px solid #1058ff !important;
  border-radius: 5px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 0px 3px !important;
  cursor: pointer !important;
}
.order_details_drawer_refund_div img {
  height: 30px !important;
  width: 30px !important;
}
.order_details_drawer_refund_div p {
  font-weight: 500 !important;
  color: #1058ff !important;
  font-size: 15px !important;
  display: flex !important;
  align-items: center !important;
}
.refund_sec .ade_points_p {
  color: #1058ff !important;
  font-size: 30px !important;
}
.refund_sec span {
  font-size: 14px !important;
  margin-left: 10px !important;
  color: #b6b6b6 !important;
}
.order_details_Payment_partial_refund {
  display: flex !important;
  align-items: center !important;
}
.order_details_Payment_partial_refund img {
  margin-top: -15px !important;
  height: 40px !important;
  width: 40px !important;
}
.order_details_Payment_partial_refund p {
  font-size: 16px !important;
  margin: 2px 0px 0px 10px !important;
}
.order_details_Payment_partial_refund .payment_partial_date {
  color: #b6b6b6 !important;
  font-size: 14px !important;
}
.Payment_partial_refund {
  padding: 7px 30px !important;
}
.order_drawer_btn {
  margin-left: 45px !important;
  height: 40px !important;
  background-color: #ebebeb !important;
  color: #1058ff !important;
  box-shadow: none !important;
  font-family: "whitney", sans-serif !important;
  font-weight: 500 !important;
}
.order_drawer_btn span {
  margin-top: 0px !important;
}
.order_details_drawer_cards_sec {
  max-height: 600px !important;
  width: 520px !important;
  border: 0.5px solid #c9c9c9 !important;
  border-radius: 5px !important;
  background-color: #fefefe !important;
}
.order_details_drawer_cards_body_div {
  padding: 20px 40px !important;
  background-color: #f3f4f6 !important;
}
.order_details_drawer_cards_body_div .MuiListItem-gutters {
  padding: 0px !important;
}
.order_details_drawer_cards_title_div {
  width: 100% !important;
  border-bottom: 0.5px solid #c9c9c9 !important;
}
.order_details_drawer_cards_title_div p {
  padding: 0px 15px !important;
  color: #666666 !important;
}
.order_details_drawer_cards_body_inner {
  padding: 10px 15px !important;
  display: flex !important;
  align-items: top !important;
  justify-content: space-between !important;
  color: #898989 !important;
}
.order_details_drawer_cards_body_inner .card_data {
  /* margin: 2px 10px 2px 0px !important; */
  width: 182px;
}
.order_details_drawer_cards_body_inner .order-id{
  width: 100% !important;
}
.order_details_drawer_cards_body_inner .card_data {
  /* margin: 2px 40px 2px 0px !important; */
}
.order_details_drawer_cards_body_inner .card_data p {
  margin: 2px 0px !important;
}
.order_details_drawer_cards_body_inner .card_data span {
  font-size: 12px !important;
}
.order_detail_refund_popup {
  padding: 20px !important;
}
.order_detail_refund_popup_btn {
  align-items: center !important;
  display: flex !important;
  justify-content: center !important;
   margin: 30px !important;
}
.refund_input_placeholder{
  font-size: 18px !important;
}
.order_detail_refund_popup_title{
  padding: 0px 30px !important;
  color: #666666;
}
.order_detail_refund_popup_title p{
  font-size: 28px;
  margin: 0 !important;
}
.order_detail_refund_popup_btn .refund_cancel_btn {
  background-color: #ebebeb !important;
  color: #666666;
  margin-left: 15px !important;
  font-size: 18px !important;
  padding: 10px;
  font-weight: 400 !important;
  text-transform: none;
}
.order_detail_refund_popup_btn .refund_save_btn {
  background-color: #00C9A5 !important;
  color: #FFFFFF;
  font-size: 18px !important;
  padding: 10px;
  font-weight: 400 !important;
  text-transform: none !important;

}
.order_detail_refund_input_div{
  margin: 40px 0px !important;
}
.MuiSelect-select {
  padding: 8px !important;
  font-size: 14px !important;
}
.table_pagination .MuiTablePagination-input {
  min-width: 50px;
}
.list-delete-btn {
  box-shadow: none !important;
  padding: 5px !important;
  font-size: 22px !important;
  min-width: 0 !important;
  margin-top: 2px !important;
  background-color: white !important;
  color: red !important;
}
.no-access-section {
  text-align: center;
  height: 85vh;
  background-color: white;
  font-size: 20px;
  .rd {
    color: red;
  }
  .gr {
    color: gray
  }
}