.warning-main {
  text-align: center;

  h5 {
    color: #666666;
    font-weight: 100;
    font-size: 1.08rem;
    line-height: 25px;
  }

  a {
    color: #01b49e !important;
  }

  button {
    cursor: pointer;
    background-color: #01b49e;
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 10px;
  }
}
