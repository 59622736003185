ul {
  padding: 0 !important;
}

hr {
  background-color: #ffffff1f !important;
  color: white !important;
}

.navlink {
  svg {
    path {
      fill: #666666 !important;
    }
  }
  span {
    color: #666666;
    font-weight: 500 !important;
    text-decoration: none;
    font-size: 16px !important;
    font-family: "SF Pro Display";
  }
}

.navlink_active {
  text-decoration: none;
  div {
    background-color: #e7e7e7e6 !important;
  }
  svg {
    path {
      fill: #01b49e !important;
    }
  }
  span {
    color: #01b49e;
    font-weight: 500 !important;
    text-decoration: none;
  }
}
.link_list_navbar{
  img{
    height: 20px !important;
    width: 20px !important;
  }
}

.css_cets00_MuiPaper_root{
  height:100vh !important;
  border-radius: 0px 7px 7px 0px !important;
}
