.dashboard-filter {
  border-bottom: 0.5px solid #d8d8d8;
  padding: 0 0 12px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .MuiSelect-select {
    border: none !important;
  }

  &-heading {
    font-size: 1.1rem;
    color: #01b49e;
    font-weight: normal;
    margin: 0;
  }

  &__filter-clear {
    &--chip {
      margin-left: 10px !important;
    }
  }
}
