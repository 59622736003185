.MuiPaper-elevation5 {
  border-radius: 8px !important;
}
.topHeader-main {
  display: flex !important;
  justify-content: flex-end !important;

  align-items: left !important;
}
.topHeader-sub-div {
  display: flex !important;
  justify-content: space-between !important;
  margin-bottom: 10px !important;
  align-items: center !important;
}
.topHeader-icon {
  color: #01b49e !important;
  height: 50px !important;
  width: 50px !important;
  border-radius: 50% !important;
}
.topHeader-icon img {
  height: 40px;
  width: 50px;
}
.topHeader-paper {
  display: flex !important;
  align-items: center !important;
  width: 500 !important;
}
.topHeader-paper-box {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}

.topHeader-profile-pic {
  height: 3rem !important;
  width: 3rem !important;
  /* border-radius: 50% !important; */
}
.topheader_popup_links{
  align-items: center !important;
  width: 120px !important;
  padding: 10px !important;
}
.topheader_popup_links a{
color: #01b49e !important;
}
.topheader_link_icons{
  width: 25px !important;
  height: 25px !important;
  margin-bottom: -5px !important;
}