.payroll-single-section {
  border: 1px solid lightgray;
  border-radius: 4px;
}
.payroll_main_div {
  background-color: #ffffff !important;
    margin-top: 15px !important;
    border-radius: 5px !important;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05) !important;
    padding-left: 1%;
    padding-right: 1%;
    color: gray;
}
.payroll_details_drawer_cards_sec {
  border: 0.5px solid #c9c9c9 !important;
  border-radius: 5px !important;
  background-color: #fefefe !important;
  width: 100%;
  margin-bottom: 15px !important;
}
.payroll_details_drawer_cards_body_inner {
  padding: 0px 15px !important;
}
.month-year-selector {
  width: 30% !important;
  position: absolute !important;
  top: 13px;
  right: 15px;
}
.payroll-input {
  width: 520px !important;
  /* height: 2px !important; */
  background-color: #FEFEFE;
}