.detail-warehouser-order {
    width: 63% !important;
}
.comment-order {
    width: 100% !important;
}
.download-invoice {
    cursor: pointer;
    text-align: right;
    color: blue
}
.download-invoice-loader {
    color: lightblue
}