.second-section {
  padding: 0;
  display: flex;
  justify-content: space-between;
  background-color: #ffffff;
  margin-bottom: 26px;

  &-card {
    // border: 0.5px solid #e9e9e9;
    // border-radius: 5px;

    &__hint{
      color: #7c7c7c;
      font-size: 0.7rem;
      margin-left: 4px;
    }
    padding: 4px 2px;

    h6 {
      margin: 0;
      font-weight: 600;
      font-size: 1.06rem;
      padding: 12px;
      color: #747474;
    }
  }

  &__left {
    width: 48.5%;
    border-right: 0.5px solid #cccccc8f;
    padding-right: 12px;
  }

  &__right {
    width: 48.5%;
  }

  &-cart {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

#chart {
  width: 100%;
}
